<template>
  <div class="meeting-list-page">
    <Header></Header>
    <div class="meeting-list-container">
      <Meeting-list-item
        v-for="(item, index) in list"
        :key="index"
        :meetingName="item.meetingName"
        :meetingDetail="item"
        @check="handleCheck(item.id)"
      ></Meeting-list-item>
    </div>
    <infinite-loading ref="infinite" @infinite="infiniteHandler">
      <span class="no-more" slot="no-more">
        暂无更多
      </span>
    </infinite-loading>
  </div>
</template>

<script>
import Header from '@/components/header/index.vue'
import MeetingListItem from './components/meetingListItem.vue'
import { fetchSDLMeetingList, fetchZYMeetingList } from '@/api/meeting'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  components: {
    Header,
    MeetingListItem,
    InfiniteLoading
  },
  data() {
    return {
      type: 'terminalOutlets',
      list: [],
      pageNo: 1,
      pageSize: 5,
      total: 0,
      id: ''
    };
  },
  activated() {
    this.type = this.$route.query.type
    this.id = this.$route.query.id || ''
    this.list = [];
    this.pageNo = 1;
    this.total = 0;
    this.$refs.infinite._events["$InfiniteLoading:reset"][0]();
  },
  methods: {
    async infiniteHandler($state) {
      await this.fetchList();
      $state.loaded();
      this.pageNo += 1;
      if (this.total - this.list.length === 0) {
        $state.complete();
      }
    },
    async fetchList() {
      let response = []
      let data = {
        "dealer_tech_type": this.type === 'terminalOutlets' ? '1' : '2',
        "kjTerminalNode": this.$route.query.id || '',
        "page.pageNo": this.pageNo,
        "page.pageSize": this.pageSize,
        "page.orderBy": "(personNum+0)",
      }
      if (this.id) {
        data.KjTerminalNode = this.id
      }
      if (this.type === 'terminalOutlets') {
        response = await fetchSDLMeetingList(data)
      }
      else if(this.type === 'ziyou') {
        response = await fetchZYMeetingList(data)
      }
      this.list = this.list.concat(response.data || [{}])
      this.total = (response.page && response.page.total) || 0
    },
    handleCheck(id) {
      this.$router.push({
        path: `/agriculturalTechnology/shidanliDetail/${id}`
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.meeting-list-page {
  min-height: 100vh;
  background: #FAFBFC;
  .meeting-list-container { 
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 6px;
  }
}
.no-more {
  font-size: 14px;
  color: #323232;
}
</style>
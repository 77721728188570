<template>
  <div class="meeting-list-item">
    <div class="button-container">
      <Button size="mini" @click="$emit('check')">查看</Button>
    </div>
    <div class="meeting-name-container">
      {{meetingName}}
    </div>
    <div
      class="detail-info-item" 
      v-for="(item, index) in labelList"
      :key="index"
    >
      <div class="label">{{item.label}}</div>
      <div class="value">{{(meetingDetail[item.field] || '--') + item.unit}}</div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/common/button/CustomButton.vue"

export default {
  components: {
    Button,
  },

  props: {
    meetingName: {
      type: String,
      default: ''
    },
    meetingDetail: {
      type: Object,
      default: ()=>{
        return {};
      }
    }
  },

  data() {
    return {
      labelList: [
        {
          label: '农技会类型',
          field: 'techconferenceType',
          unit: ''
        },
        {
          label: '所属终端商',
          field: 'kjTerminalNodeName',
          unit: ''
        },
        {
          label: '农技会时间',
          field: 'holdTime',
          unit: ''
        },
        {
          label: '参会人数',
          field: 'personNum',
          unit: '人'
        },
        {
          label: '订肥量',
          field: 'orderNum',
          unit: '吨'
        },
      ]
    };
  }
}
</script>

<style lang="scss" scoped>
.meeting-list-item {
  width: 351px;
  height: 170px;
  background: #FFFFFF;
  box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
  opacity: 1;
  border-radius: 10px;
  position: relative;
  box-sizing: border-box;
  padding: 12px 12px 0;
  text-align: left;
  margin-bottom: 12px;

  .button-container {
    position: absolute;
    top: 12px;
    right: 12px;
  }

  .meeting-name-container {
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    color: #2C2C2C;
    font-weight: bold;
    margin-bottom: 11px;
  }
  
  .detail-info-item {
    height: 20px;
    display: flex;
    align-items: center;
    font-size: 14px;

    &:not(:last-child) {
      margin-bottom: 3px;
    }

    .label {
      width: 70px;
      color: #818181;
      text-align: left;
    }
    .value {
      margin-left: 6px;
      color: #2C2C2C;
    }

  }

}
</style>